/* You can add global styles to this file, and also import other style files */
@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "~material-design-icons/iconfont/material-icons.css";
@import "@angular/material/theming";
@import "./themes.scss";
@include mat-core();

$custom-typography: mat-typography-config(
  $font-family: $font-regular,
  $display-4: mat-typography-level(112px, 112px, 300, $letter-spacing: -0.05em),
  $display-3: mat-typography-level(56px, 56px, 400, $letter-spacing: -0.02em),
  $display-2: mat-typography-level(45px, 48px, 400, $letter-spacing: -0.005em),
  $display-1: mat-typography-level(32px, 40px, 400),
  $headline: mat-typography-level(32px),
  $title: mat-typography-level(20px, 32px, 500),
  $subheading-2: mat-typography-level(16px, 28px, 400),
  $subheading-1: mat-typography-level(16px, 24px, 400),
  $body-2: mat-typography-level(14px, 24px, 500),
  $body-1: mat-typography-level(16px, 24px, 500),
  $caption: mat-typography-level(14px, 20px, 400),
  $button: mat-typography-level(14px, 14px, 500),
  $input: mat-typography-level(inherit, 1.125, 400),
);
@include angular-material-typography($custom-typography);

$candy-app-primary: mat-palette($app-primary);
$candy-app-accent: mat-palette($app-accent);
$candy-app-warn: mat-palette($app-error);
$candy-app-theme: mat-light-theme(
  (
    color: (
      primary: $candy-app-primary,
      accent: $candy-app-accent,
      warn: $candy-app-warn,
    ),
  )
);
@include angular-material-theme($candy-app-theme);

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: $font-regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: auto;
}

.text-align-left {
  text-align: left !important;
}
.text-align-right {
  text-align: right !important;
}

.pos-absolute {
  position: absolute;
}

.cursor-pointer {
  cursor: pointer;
}

.overflow-scroll {
  overflow: auto;
}
.overflow-scroll-y {
  overflow-y: auto;
}

.overflow-hidden {
  overflow: hidden;
}

$values: 0 1 2 3 4 5 6 7 8 9 10 11 12 13 14 15 16 17 18 19 20 21 22 23 24 25 26 27 28 29 30 31 32 33 35 36 37 38 39 40
  41 43 44 45 47 48 50 52 55 56 60 65 70 72 80 90 100 130 140 160 180 257 280 300;

@for $v from 1 through length($values) {
  .m#{nth($values, $v)} {
    margin: calc(nth($values, $v)) + px !important;
  }
  .p#{nth($values, $v)} {
    padding: calc(nth($values, $v)) + px !important;
  }
  .mt#{nth($values, $v)} {
    margin-top: calc(nth($values, $v)) + px !important;
  }
  .mbt#{nth($values, $v)} {
    margin-bottom: calc(nth($values, $v)) + px !important;
  }
  .ml#{nth($values, $v)} {
    margin-left: calc(nth($values, $v)) + px !important;
  }
  .mr#{nth($values, $v)} {
    margin-right: calc(nth($values, $v)) + px !important;
  }
  .pt#{nth($values, $v)} {
    padding-top: calc(nth($values, $v)) + px !important;
  }
  .pbt#{nth($values, $v)} {
    padding-bottom: calc(nth($values, $v)) + px !important;
  }
  .pl#{nth($values, $v)} {
    padding-left: calc(nth($values, $v)) + px !important;
  }
  .pr#{nth($values, $v)} {
    padding-right: calc(nth($values, $v)) + px !important;
  }
  .p-bt-t#{nth($values, $v)} {
    padding: calc(nth($values, $v)) + px 0px !important;
  }
  .t#{nth($values, $v)} {
    top: calc(nth($values, $v)) + px !important;
  }
  .r#{nth($values, $v)} {
    right: calc(nth($values, $v)) + px !important;
  }
  .b#{nth($values, $v)} {
    bottom: calc(nth($values, $v)) + px !important;
  }
  .l#{nth($values, $v)} {
    left: calc(nth($values, $v)) + px !important;
  }
}

$valueFont: 9 10 11 12 13 14 15 16 17 18 19 20 21 22 24 28 30 31 32 35 40 42 48 54;
@for $v from 1 through length($valueFont) {
  .f#{nth($valueFont, $v)} {
    font-size: nth($valueFont, $v) + px !important;
  }
}

$valueHeight: 0 22 24 47 50 60 70 72 80 90 130 180 186 260 272 485 710 830;
@for $v from 1 through length($valueHeight) {
  .h#{nth($valueHeight, $v)}px {
    height: nth($valueHeight, $v) + px !important;
  }
}

$valueWidth: 1 4 5 8 9 10 12 15 20 21 22 23 25 26 27 28 30 31 32 35 39 40 45 46 48 50 55 57 60 65 70 71 74 75 79 80 82
  85 87 90 93 95 96 97 98 99 100 126 155 170 200;
@for $v from 1 through length($valueWidth) {
  .w#{nth($valueWidth, $v)}p {
    width: percentage($number: calc(nth($valueWidth, $v) / 100)) !important;
  }
  .h#{nth($valueWidth, $v)}p {
    height: percentage($number: calc(nth($valueWidth, $v) / 100)) !important;
  }
  .maxw#{nth($valueWidth, $v)}p {
    max-width: percentage($number: calc(nth($valueWidth, $v) / 100)) !important;
  }
  .maxh#{nth($valueWidth, $v)}p {
    max-height: percentage($number: calc(nth($valueWidth, $v) / 100)) !important;
  }
  .minw#{nth($valueWidth, $v)}p {
    min-width: percentage($number: calc(nth($valueWidth, $v) / 100)) !important;
  }
  .minh#{nth($valueWidth, $v)}p {
    min-height: percentage($number: calc(nth($valueWidth, $v) / 100)) !important;
  }
  .maxw#{nth($valueWidth, $v)}vw {
    max-width: percentage($number: calc(nth($valueWidth, $v) / 100)) !important;
  }
}

$valueWidthP: 0 10 12 13 15 16 17 18 20 21 22 23 24 25 26 27 28 30 31 32 33 35 36 37 38 39 40 41 42 43 44 45 46 47 48 49
  50 55 56 60 61 63 64 66 70 71 72 75 79 80 85 95 99 100 105 115 119 120 122 126 128 137 138 140 150 157 160 164 169 170
  175 180 200 210 215 218 225 230 240 250 260 270 280 285 300 320 350 360 380 400 495 500 630 700;

@for $v from 1 through length($valueWidthP) {
  .w#{nth($valueWidthP, $v)}px {
    width: nth($valueWidthP, $v) + px !important;
  }
  .h#{nth($valueWidthP, $v)}px {
    height: nth($valueWidthP, $v) + px !important;
  }
  .maxw#{nth($valueWidthP, $v)}px {
    max-width: nth($valueWidthP, $v) + px !important;
  }
  .maxh#{nth($valueWidthP, $v)}px {
    max-height: nth($valueWidthP, $v) + px !important;
  }
  .minw#{nth($valueWidthP, $v)}px {
    min-width: nth($valueWidthP, $v) + px !important;
  }
  .minh#{nth($valueWidthP, $v)}px {
    min-height: nth($valueWidthP, $v) + px !important;
  }
  .lh#{nth($valueWidthP, $v)}px {
    line-height: nth($valueWidthP, $v) + px !important;
  }
}

.heading1 {
  font-family: $font-semibold;
  font-size: 32px;
}

.heading2 {
  font-family: $font-semibold;
  font-size: 24px;
}

.heading3 {
  font-family: $font-semibold;
  font-size: 20px;
}

.text {
  font-family: $font-semibold;
  font-size: 16px;
}

.table-text {
  font-family: $font-regular;
  font-size: 14px;
}

html {
  height: 100%;
  body {
    height: 100%;
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
  }
}

.color-primary-bg {
  background-color: $color-primary;
}

.color-error-bg {
  background-color: $color-error;
}
.color-primary-font-color {
  color: $color-primary;
}
.color-error-font-color {
  color: $color-error !important;
}
.color-secondary-bg {
  background-color: $color-grey5;
}
.color-secondary-font-color {
  color: $color-grey5;
}
.color-grey1-bg {
  background-color: $color-grey1;
}
.color-grey4-bg {
  background-color: $color-grey4;
}
.color-grey5-bg {
  background-color: $color-grey5;
}
.color-grey6-bg {
  background-color: $color-grey6;
}
.color-grey1-bg-opacity-8 {
  background-color: $color-grey1-opacity-8;
}
.color-grey2-font-color {
  color: $color-grey2;
}
.color-grey4-font-color {
  color: $color-grey4;
}
.color-grey2-fill-color {
  fill: $color-grey2;
}
.color-grey4-fill-color {
  fill: $color-grey4;
}
.color-white-font-color {
  color: $color-white;
}
.color-black-font-color {
  color: $color-black;
}
.color-black-fill-color {
  fill: $color-black;
}

.color-white-bg {
  background-color: $color-white;
}
.color-black-bg {
  background-color: $color-black;
}
.color-warning-font-color {
  color: $color-warning;
}
.font-overpass-regular {
  font-family: $font-regular;
}
.font-overpass-semibold {
  font-family: $font-semibold;
}
.font-overpass-bold {
  font-family: $font-bold;
}

.pos-relative {
  position: relative;
}
.pos-fixed {
  position: fixed;
}
.header-panel + .cdk-overlay-connected-position-bounding-box {
  top: 78px !important;
  right: 0px !important;
  .mat-menu-panel {
    border-radius: 0px !important;
    max-width: 284px;
    min-width: 284px !important;
  }
  .mat-menu-content {
    padding: 0px !important;
  }
}

.create-case-select-panel,
.create-info-select-panel .create-intelligence-select-panel {
  max-height: 200px !important;
}

.table-container {
  box-shadow: none;
  border-top: 1px solid $color-grey5;
}
.table-row {
  border-color: $color-grey6;
}

.rel-position {
  position: relative;
}
.abs-position {
  position: absolute;
}
.align-center {
  text-align: center;
}

.icon-fill-white {
  fill: $color-white;
}
.icon-fill-primary {
  fill: $color-primary;
}

.email-svg {
  background-color: white; /* defines the background color of the image */
  mask: url(assets/icons/email-line.svg) no-repeat center / contain;
  -webkit-mask: url(assets/icons/email-line.svg) no-repeat center / contain;
  height: 14px !important;
  margin-top: 12%;
}

.email-svg:hover {
  background-color: white; /* defines the background color of the image */
  mask: url(assets/icons/email-fill.svg) no-repeat center / contain;
  -webkit-mask: url(assets/icons/email-fill.svg) no-repeat center / contain;
  height: 14px !important;
  margin-top: 12%;
}

.email-svg-active {
  background-color: white; /* defines the background color of the image */
  mask: url(assets/icons/email-fill.svg) no-repeat center / contain;
  -webkit-mask: url(assets/icons/email-fill.svg) no-repeat center / contain;
  height: 14px !important;
  margin-top: 12%;
}

.icon-fill-primary {
  fill: $color-primary;
}

.icon-fill-gre2 {
  fill: $color-grey2;
}

.pointer-event-none {
  pointer-events: none !important;
}

.mat-drawer-container {
  background-color: $color-white !important;
}

.mat-drawer-content {
  overflow: hidden !important;
}

//Customize mat-dialog
.createCasePopup,
.createTaskPopup,
.createIntelligencePopup,
.caseFilterPopup,
.entityFilterPopup,
.commonMapping,
.discardPopup,
.addRelationshipLinkPopup {
  max-width: 100% !important;
  .mat-dialog-container {
    padding: 0px !important;
  }
  @media screen and (max-width: 599px) {
    .mat-dialog-container {
      border-radius: 0px !important;
    }
  }
}
// .createAlertPopup{
//     .mat-dialog-container {
//         height: 83% !important;
//     }

// }

.caseFilterPopup,
.entityFilterPopup,
.quickFilter {
  .mat-form-field-wrapper {
    padding-bottom: 0px !important;
  }

  input[type="checkbox" i] {
    margin: 0 5px 0 0 !important;
  }
}

.caseFilterPopup {
  .form-control-label {
    color: $color-black;
  }
}

.caseFilterPopup {
  .mat-form-field-subscript-wrapper {
    top: 100%;
  }
}

.popupBackDrop {
  background: $color-white !important;
  opacity: 0.9 !important;
}

.filterCasePopUp {
  max-width: 1198px;
  max-height: 772px;
  .mat-dialog-container {
    padding: 0px !important;
  }
}

.photoUploadPopup {
  max-width: 100% !important;
  .mat-dialog-container {
    padding: 0px !important;
    height: 97% !important;
  }
  .cropper::after {
    border: 2px solid $color-primary !important;
  }
  .cropper {
    color: $color-primary !important;
  }
  @media screen and (max-width: 599px) {
    .mat-dialog-container {
      border-radius: 0px !important;
      overflow: hidden;
    }
  }
}

.entityFilterPopup {
  .datePicker .mat-form-field-wrapper {
    padding-bottom: 0px !important;
  }
}

.mat-dialog-container {
  border-radius: 8px !important;
  box-shadow: 0 2px 30px 0 rgba(191, 191, 191, 0.5);
}

.updateCasePopUp {
  max-width: 800px;
  max-height: 463px;
  .mat-dialog-container {
    padding: 0px !important;
    border-radius: 0px !important;
  }
}

.mat-form-field.mat-accent .mat-input-element {
  caret-color: $color-primary !important;
}

th.mat-header-cell:last-of-type,
td.mat-cell:last-of-type,
td.mat-footer-cell:last-of-type {
  padding-right: 0px !important;
}
.mat-form-field-required-marker {
  color: $color-black !important;
}

.text-ellipsis {
  // white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
}

.text-ellipsis-multi-line {
  text-overflow: ellipsis;
  overflow: hidden;
  min-width: 0;
  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* autoprefixer: ignore next */
    -webkit-box-orient: verticalc;
  }
}
.no-wrap-text {
  white-space: nowrap;
}
.hidden-overflow-x {
  overflow-x: hidden !important;
}
.overflow-y-overlay {
  overflow-y: overlay !important;
}
.color-gray2-bg {
  background-color: $color-grey2;
}
.color-gray2-fill {
  fill: $color-grey2;
}
.color-error-fill {
  fill: $color-error;
}
.color-error-bg {
  background-color: $color-error;
}
.color-success-stroke {
  stroke: $color-success;
}
.color-warning-stroke {
  stroke: $color-warning;
}
.color-primary-stroke {
  stroke: $color-primary;
}
.color-primary-bg {
  background-color: $color-primary;
}
.color-grey2-stroke {
  stroke: $color-grey2;
}
.color-error-stroke {
  stroke: $color-error;
}
.color-gray8-bg {
  background-color: $color-grey8;
}
.border-box {
  box-sizing: border-box;
}
.limit-line-wrap {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: verticalc;
  word-break: break-all;
}
.three-line-wrap {
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: verticalc;
  word-break: break-all;
}
.scroll-overflow-y {
  overflow-y: auto !important;
}

.pointer {
  cursor: pointer;
}
.relationship-circle {
  box-sizing: border-box;
  display: inline-flex;
  background-color: $color-grey8;
  &:hover {
    border: 1px solid $color-black;
    text-decoration: underline;
  }
  border: 1px solid transparent;
  border-radius: 50%;
}
.image-outline-circle {
  box-sizing: border-box;
  display: inline-flex;
  border-radius: 50%;
  border: 1px solid $color-grey6;
}
.display-inline-block {
  display: inline-block !important;
}
.mat-snack-bar-container {
  min-width: 0px !important;
  height: 0px !important;
  padding: 0px !important;
  margin: 0px !important;
  color: transparent !important;
  background: none !important;
  box-shadow: none !important;
}
.toltip-auto-width {
  max-width: unset !important;
}
:focus {
  outline: 0 !important;
}
.loader {
  box-sizing: border-box;
  background-repeat: no-repeat;
  background: linear-gradient(#f6f6f6 0%, #e4e4e4 100%) !important;
  background-color: #e4e4e4 !important;
  overflow: hidden;
  position: relative;
}
.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: $color-primary;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: $color-primary;
}

.pointer-event {
  pointer-events: all;
}

.break-all-word {
  word-break: break-all;
}

.break-word {
  word-break: break-word;
}
.mat-input-element:disabled {
  border-bottom: none !important;
  color: $color-black !important;
}
#toast-container {
  .mat-progress-spinner svg {
    width: 25px !important;
    height: 25px !important;
  }
}
.white-space-nowrap {
  white-space: nowrap;
}
.display-none {
  display: none;
}
.text-decoration-line-through {
  text-decoration: line-through;
}
.text-decoration-none {
  text-decoration: none !important;
}
.mat-autocomplete-panel-custom {
  box-sizing: border-box;
  border: 2px solid $color-primary;
  border-radius: 4px;
  background: $color-white;
  box-shadow: none !important;
}
.font-style-italic {
  font-style: italic;
}
common-mapping,
common-attachment {
  height: inherit;
}

.commonMapping {
  max-height: 700px;
}

.discardPopup {
  max-width: 800px !important;
  max-height: 380px !important;
}

@media screen and (max-width: 599px) {
  .discardPopup {
    max-width: 800px !important;
    max-height: 100% !important;
  }
}

.popupSearchBackDrop {
  background-color: rgba(33, 33, 33, 0.8);
}
.stacklabel {
  position: relative;
  top: -10px !important;
  padding-left: 8px;
}
.highcharts-tooltip span {
  background-color: white;
  border-left: 2px solid white;
  opacity: 1;
  z-index: 9999 !important;
}
.text-transform-lowercase {
  text-transform: lowercase !important;
}
.text-transform-uppercase {
  text-transform: uppercase !important;
}
.highcharts-container {
  overflow-y: hidden !important;
  overflow-x: auto !important;
}
.mapping-panel .panel-item {
  margin-top: 0px !important;
}
.p32pbt0 {
  padding: 32px 32px 0 32px;
}
.mTopAuto {
  margin-top: auto;
}
.h99vh {
  height: 99vh !important;
}
.mBottomAuto {
  margin-bottom: auto;
}
app-search-page {
  height: 100%;
}
.visbility-hidden {
  visibility: hidden;
}
.item-center {
  align-items: center;
}

.display-flex {
  display: flex;
}

.display-flex {
  display: flex !important;
}

img[src=""],
img:not([src]) {
  visibility: hidden;
}
/* Droppable */
.drag-hint-border {
  border: $color-primary !important;
}

.drag-over-border {
  border: $color-primary dashed 2px !important;
}
.drag-transit {
  border: $color-primary dashed 2px !important;
}
/* Draggable*/
.drag-border {
  border: $color-primary !important;
}

.mlm9 {
  margin-left: -9px !important;
}

.ng2-pdf-viewer-container .page,
.ng2-pdf-viewer-container .canvasWrapper,
.ng2-pdf-viewer-container canvas {
  width: 100% !important;
  height: 100% !important;
}
.otpBackDrop {
  top: 242px !important;
  background: rgba(120, 120, 120) !important;
  opacity: 0.9 !important;
}
@media only screen and (max-width: 2640px) and (min-width: 600px) {
  .verifyOtpPopup {
    max-width: 50% !important;
    width: 400px !important;
    height: auto !important;
    position: fixed !important;
    top: 62% !important;
    left: 50% !important;
    transform: translate(-63%, -50%) !important;
    margin: 0 auto !important;
    .mat-dialog-container {
      padding: 0px !important;
      position: relative !important;
      top: 50px !important;
      overflow: hidden !important;
      .text-description {
        font-size: 16px !important;
        line-height: 22px !important;
      }
    }
  }
}
@media screen and (max-width: 599px) {
  .verifyOtpPopup {
    max-width: 100% !important;
    width: 400px !important;
    height: auto !important;
    position: fixed !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    margin: 0 auto !important;
    .mat-dialog-container {
      padding: 0px !important;
      position: relative !important;
      top: 50px !important;
      overflow: hidden !important;
      .text-description {
        font-size: 14px !important;
        line-height: 18px !important;
      }
    }
  }
}

app-my-details {
  height: 100% !important;
}

.zindex-1 {
  z-index: 1;
}
.flex-25 {
  flex: 1 1 25% !important;
  box-sizing: border-box;
  max-width: 25% !important;
}

::ng-deep .mat-raised-button.mat-accent {
  color: $color-black !important;
}

.secondary-button-text-color {
  color: $color-black !important;
}
