// Blue
// RGB : 6, 58, 131
// CMYK : 100, 87, 20, 6
// PMS : 534
// HSV : 220%, 72%, 49%
// HEX : #063a83

// Accents:
// Gold
// CMYK: C5 M36 Y95 K0
// RGB: R239 G170 B43
// Hex#: #efaa2b

// Green:
// C 70 M 3 Y 62 K 0
// R 70 / G 181 / B 135
// #46b587

// White
// White – when logo will not appear in blue
// (e.g. on blue/black background)

// FONT
// Complimentary typefaces: regular and extra condensed versions of Proxima Nova
// Tagline font: Open Sans


// Colors
$color-primary: #063a83;
$color-accent: #efaa2b;
$color-green: #46b587;
$color-black: #212121;
$color-white: #ffffff;
$color-teritary: #55afff;
$color-full-black: #000000;
$border-color:#f4f5f7;

$color-grey1: #4b4b4b;
$color-grey2: #6c6c6c;
$color-grey3: #808080;
$color-grey4: #a8a8a8;
$color-grey5: #c5c5c5;
$color-grey6: #e4e4e4;
$color-grey7: #f0f0f0;
$color-grey8: #f6f6f6;
$color-grey9: #cfd4db;
$color-grey1-opacity-8: #4b4b4bcc;

$color-success: #46b587;
$color-warning: #ffbb38;
$color-error: #e3174b;

$shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3);
$gradiant: linear-gradient(45deg, #f6f6f6 0%, #e4e4e4 100%);

// Font
$font-semibold: proxima-nova-semibold,'Open Sans', sans-serif;
$font-regular: proxima-nova-regular,'Open Sans', sans-serif;
$font-bold: proxima-nova-bold,'Open Sans', sans-serif;
$font-montserrat-semibold: montserrat-semibold,'Open Sans', sans-serif;

$app-primary: (
  50: #e2eff9,
  100: #b8d8f1,
  200: #8cc0e8,
  300: #62a7de,
  400: #4395d9,
  500: #2685d3,
  600: #2078c6,
  700: #1967b4,
  800: #1256a2,
  900: #063a83,
  A100: #ffffff,
  A200: #f2f6ff,
  A400: #bfd3ff,
  A700: #a6c1ff,
  contrast: (
    50: $color-black,
    100: $color-black,
    200: $color-black,
    300: $color-black,
    400: $color-black,
    500: $color-white,
    600: $color-white,
    700: $color-white,
    800: $color-white,
    900: $color-white,
    A100: $color-black,
    A200: $color-white,
    A400: $color-white,
    A700: $color-white,
  )
);

$app-accent: (
  50: #fdfde8,
  100: #fbf9c6,
  200: #f9f6a1,
  300: #f7f17b,
  400: #f3ed5e,
  500: #f0e842,
  600: #f3d93f,
  700: #f1c235,
  800: #efaa2b,
  900: #eb8319,
  A100: #ffffff,
  A200: #ffffff,
  A400: #ffffff,
  A700: #ffffff,
  contrast: (
    50: $color-black,
    100: $color-black,
    200: $color-black,
    300: $color-black,
    400: $color-black,
    500: $color-white,
    600: $color-white,
    700: $color-white,
    800: $color-white,
    900: $color-white,
    A100: $color-black,
    A200: $color-white,
    A400: $color-white,
    A700: $color-white,
  )
);

$app-error: (
    50: #fce3e9,
    100: #f7b9c9,
    200: #f18ba5,
    300: #eb5d81,
    400: #e73a66,
    500: #e3174b,
    600: #e01444,
    700: #dc113b,
    800: #d80d33,
    900: #d00723,
    A100: #fffafa,
    A200: #ffc7cd,
    A400: #ff949f,
    A700: #ff7a88,
  contrast: (
    50: $color-black,
    100: $color-black,
    200: $color-black,
    300: $color-black,
    400: $color-black,
    500: $color-white,
    600: $color-white,
    700: $color-white,
    800: $color-white,
    900: $color-white,
    A100: $color-black,
    A200: $color-white,
    A400: $color-white,
    A700: $color-white,
  )
);


