@font-face {
    font-family: "proxima-nova-black";
    src: url("./assets/fonts/ProximaNova/Proxima Nova Black.otf") format("truetype");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: "proxima-nova-bold";
    src: url("./assets/fonts/ProximaNova/Proxima Nova Bold.otf") format("truetype");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: "proxima-nova-regular";
    src: url("./assets/fonts/ProximaNova/ProximaNova-Regular.otf") format("truetype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: "proxima-nova-semibold";
    src: url("./assets/fonts/ProximaNova/Proxima Nova Semibold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: "montserrat-semibold";
    src: url("./assets/fonts/Montserrat/Montserrat-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }
  