@import "themes.scss";

.primary-raised-button {
  background-color: $color-primary;
  color: $color-white;
  padding: 0px !important;
  &:hover {
    background-color: $color-teritary;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3) !important;
  }
  &:active {
    background-color: $color-teritary;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3) !important;
  }
  ::ng-deep .mat-progress-spinner circle,
  .mat-spinner circle {
    stroke: $color-white;
  }
}
.secondary-raised-button {
  background-color: $color-grey7;
  color: $color-grey2;
  padding: 0px !important;
  &:hover {
    background-color: $color-grey5;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  }
  &:active {
    background-color: $color-grey5;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  }
  ::ng-deep .mat-progress-spinner circle,
  .mat-spinner circle {
    stroke: $color-primary;
  }
}

.error-raised-button {
  background-color: $color-error;
  color: $color-white;
  padding: 0px 8px !important;
  &:hover {
    background-color: $color-error;
  }
  ::ng-deep .mat-progress-spinner circle,
  .mat-spinner circle {
    stroke: $color-white;
  }
}

.primary-flat-button {
  padding: 0px 8px !important;
  height: 36px !important;
  &:hover {
    background-color: $color-teritary;
  }
  ::ng-deep .mat-progress-spinner circle,
  .mat-spinner circle {
    stroke: $color-white;
  }
}
.secondary-flat-button {
  padding: 0px !important;
  &:hover {
    background-color: $color-grey7;
  }
  ::ng-deep .mat-progress-spinner circle,
  .mat-spinner circle {
    stroke: $color-primary;
  }
}
.menu-button {
  border: 2px solid $color-grey1;
  padding: 0 8px 0 12px;
  text-align: left;
  &:hover,
  &:active {
    background-color: $color-grey8;
  }
  .menu-button-arrow {
    position: absolute;
    right: 3px;
    top: 10px;
  }
  &.menu-opened {
    background-color: $color-grey8;
  }
}
.menu-button-option-icon {
  color: $color-primary;
}

.mat-raised-button {
  box-shadow: none !important;
}

.buttonDisabled {
  color: $color-grey3 !important;
  cursor: no-drop !important;
}

.mat-raised-button.mat-button-disabled.mat-button-disabled,
.mat-flat-button.primary-flat-button.mat-button-disabled {
  color: $color-white !important;
  background-color: $color-grey5 !important;
}

.mat-flat-button.secondary-flat-button.mat-button-disabled {
  color: $color-grey5 !important;
  background-color: $color-white !important;
}

.secondary-flat-button-loading {
  background-color: $color-grey6 !important;
}
.action-icon-xs {
  height: 24px;
  width: 24px;
  margin-right: 11px;
  position: relative;
  top: 12px;
  background-color: $color-primary;
  -webkit-mask-size: contain; /* OR cover OR fixed values */
  -webkit-mask-position: center;
  -webkit-mask-repeat: no-repeat;
  mask-size: contain; /* OR cover OR fixed values */
  mask-position: center;
  mask-repeat: no-repeat;
}
@media screen and (min-width: 600px) and (max-width: 960px) {
  .buttonWidth {
    min-width: 100px !important;
  }
}

@media screen and (min-width: 960px) {
  .buttonWidth {
    min-width: 120px !important;
  }
}
.mat-icon-button {
  // width: 24px!important;
}
::ng-deep .common-mat-menu-panel {
  min-width: 240px !important;
  margin: 8px 0;
  min-height: auto;
  .mat-menu-content:not(:empty) {
    padding: 4px 0;
    .mat-menu-item {
      padding: 0 8px;
    }
  }
}

.menu-spacing {
  margin-bottom: 0px !important;
  margin-left: -5px !important;
}

.display-none {
  display: none;
}

.no-drop {
  cursor: no-drop !important;
}

.XLButton {
  border: none;
  background: none;
  border-radius: 4px;
  padding: 20px;
  background-color: $color-grey8;
}

.XLButton:hover {
  background-color: $color-teritary;
}

.XLAddButton {
  border: none;
  background: none;
  border-radius: 4px;
  padding: 20px 30px 20px 15px;
  background-color: $color-grey8;
}

.XLAddButton:hover {
  background-color: $color-grey6;
  color: $color-black !important;
}

.XLSelectButton {
  border: none;
  background: none;
  border-radius: 4px;
  padding: 20px 30px 20px 15px;
  background-color: $color-grey8;
}

.XLSelectButton:hover {
  background-color: $color-grey6;
  color: $color-black !important;
}

.icon-btn {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding: 0px;
  min-width: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: $color-grey6 !important;
  }
}
.opened {
  background-color: #bfdefe !important;
}

.border-highlight {
  border-radius: 4px !important;
  border: 2px solid #19b262 !important;
  background-color: #e4e4e4;
}

::ng-deep .XLAddButton .mat-checkbox-frame {
  border-radius: 50% !important;
}

::ng-deep .XLAddButton .mat-checkbox-checked .mat-checkbox-background {
  background-color: #19b262 !important;
  border-radius: 50% !important;
}

::ng-deep .XLSelectButton .mat-checkbox-checked .mat-checkbox-background {
  background-color: $color-primary !important;
}

::ng-deep .mat-checkbox-inner-container {
  width: 20px !important;
  height: 20px !important;
}
::ng-deep .XLSelectButton .mat-checkbox-inner-container {
  width: 20px !important;
  height: 20px !important;
  margin-bottom: -24px !important;
}

.XLSelectButton-highlight {
  border: 2px solid $color-primary !important;
  background-color: $color-grey6 !important;
}

.sanctionList {
  padding: 20px 30px 30px 15px;
  font-size: 16px;
}
